@import "variables";
//Bootstrap Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.textpic-fullwidth {
    @include media-breakpoint-up(lg) {
        .textpic-content {
            padding-right: 60px;
        }

        picture.image {
            margin-left: 0;
            margin-right: calc(-50vw + 100%);
        }
    }

    &.textpic-fullwidth-left {
        .row {
            flex-direction: row-reverse;
        }

        @include media-breakpoint-up(lg) {
            .textpic-content {
                padding-left: 60px;
                padding-right: 0;
            }

            picture.image {
                margin-left: calc(-50vw + 100%);
                margin-right: 0;
            }
        }
    }
}
